import React from 'react';
import styled from 'styled-components';

export default function() {
  return <BackgroundOverlay />
}

/* prettier-ignore */
const BackgroundOverlay = styled.div`
  background: linear-gradient(
    90deg,
    rgba(000, 000, 000, 1) 7%,
    rgba(000, 000, 000, 0.8) 14%,
    transparent 30%,
    transparent 70%,
    rgba(000, 000, 000, 0.8) 86%,
    rgba(000, 000, 000, 1) 93%
  );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
