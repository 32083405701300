import React from 'react';
import styled from 'styled-components';
import { BackgroundOverlay } from 'components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { mq } from 'sharedStyles';
import Tile from 'images/shared/tile.png';

export default function() {
  return (
    <Hero>
      <BackgroundOverlay />
      <StaticQuery
        query={graphql`
          query {
            heroLogo: file(relativePath: { eq: "shared/hero_logo.png" }) {
              childImageSharp {
                fluid(maxWidth: 550, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={({ heroLogo }) => (
          <Link to="/">
            <HeroImg fluid={heroLogo.childImageSharp.fluid} />
          </Link>
        )}
      />
    </Hero>
  );
}

/* prettier-ignore */
const Hero = styled.section`
  background: #000 url(${Tile});
  padding: 1em 0 1.5em;
  position: relative;
  width: 100%;
  padding-top: 4.6em;

  ${mq.landscapeMobile`
    padding-top: 0.9em;
  `}
`;

const HeroImg = styled(Img)`
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
`;
