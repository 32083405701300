import * as React from 'react';

function SvgPlayIcon(props) {
  return (
    <svg viewBox="0 0 12 12" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1" fill="#f1e543">
          <path
            d="M5.64 0h.83c.29 0 .58.08.87.14a5.42 5.42 0 011.8.73 6 6 0 012.27 2.53 5.83 5.83 0 01.52 1.71c0 .18.05.35.07.52v.82c0 .29-.08.58-.14.87a5.42 5.42 0 01-.73 1.8 5.76 5.76 0 01-1.25 1.45 6 6 0 01-1.23.8 5.92 5.92 0 01-1.65.54l-.64.09h-.83c-.39-.07-.78-.12-1.16-.21a5.73 5.73 0 01-1.58-.7A6.48 6.48 0 011.5 10a6 6 0 01-.92-1.4 5.84 5.84 0 01-.5-1.65c0-.19 0-.38-.08-.57v-.74a.5.5 0 000-.12 6.57 6.57 0 01.23-1.16 6.07 6.07 0 01.71-1.58 5.86 5.86 0 012.5-2.2A5.94 5.94 0 015 .09zM1.77 6A4.23 4.23 0 106 1.77 4.22 4.22 0 001.77 6z"
            opacity={0.85}
          />
          <path d="M4.81 8.23a.29.29 0 01-.28-.3v-.57V4.2c0-.31.23-.43.5-.28l.37.22 2.12 1.24c.22.13.44.25.65.39s.27.41 0 .58l-1.23.71L5.36 8 5 8.19z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgPlayIcon;
