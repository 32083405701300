import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { mq } from 'sharedStyles';
import Img from 'gatsby-image';
import styled from 'styled-components';

export default function() {
  return (
    <StaticQuery
      query={graphql`
        query {
          sportIrelandLogo: file(
            relativePath: { eq: "shared/sport_ireland_logo_white.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 130, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={({ sportIrelandLogo }) => {
        return (
          <a href="https://www.sportireland.ie/" target="__blank">
            <SportIrelandImg
              style={{
                position: 'absolute'
              }}
              alt="Sport Ireland Logo"
              fluid={sportIrelandLogo.childImageSharp.fluid}
            />
          </a>
        );
      }}
    />
  );
}

/* prettier-ignore */
const SportIrelandImg = styled(Img)`
  position: absolute;
  max-width: 130px;
  width: 100%;
  top: 12%;
  right: 2%;

  ${mq.landscapeMobile`
    top: 17%;
    right: 1.5%;
  `}
`;
