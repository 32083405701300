import React, { Component } from 'react';
import { SportIrelandLogo } from 'components';
import { Link } from 'gatsby';
import { slide as BurgerMenu } from 'react-burger-menu';
import { flexStyle, mq } from 'sharedStyles';
import styled from 'styled-components';
import 'sharedStyles/hamburger.css';

export default class extends Component {
  state = {
    isOpen: false
  };

  handleStateChange = (state) => {
    this.setState({ isOpen: state.isOpen });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <>
        <TabletAndDesktopHeader>
          <Menu>
            <MenuLink to="/" activeStyle={activeStyle}>
              Home
            </MenuLink>
            <MenuLink to="/athletes/" activeStyle={activeStyle}>
              Athletes
            </MenuLink>
            <MenuLink to="/about/" activeStyle={activeStyle}>
              About
            </MenuLink>
            <MenuLink to="/policy/" activeStyle={activeStyle}>
              Policy
            </MenuLink>
          </Menu>
          <SportIrelandLogo />
        </TabletAndDesktopHeader>
        <MobileHeader>
          <SportIrelandLogo />
          <BurgerMenu
            width="60vw"
            isOpen={isOpen}
            onStateChange={(state) => this.handleStateChange(state)}>
            <MenuLink
              onClick={() => this.closeMenu()}
              activeStyle={{ ...mobileActiveStyle }}
              to="/">
              Home
            </MenuLink>
            <MenuLink
              onClick={() => this.closeMenu()}
              activeStyle={{ ...mobileActiveStyle }}
              to="/athletes/">
              Athletes
            </MenuLink>
            <MenuLink
              onClick={() => {
                this.closeMenu();
              }}
              activeStyle={{ ...mobileActiveStyle }}
              to="/about/">
              About
            </MenuLink>
            <MenuLink
              onClick={() => this.closeMenu()}
              activeStyle={{ ...mobileActiveStyle }}
              to="/policy/">
              Policy
            </MenuLink>
          </BurgerMenu>
        </MobileHeader>
      </>
    );
  }
}

/* prettier-ignore */
const activeStyle = {
  borderBottomColor: `var(--custom-yellow)`,
  borderBottomStyle: 'solid',
  borderBottomWidth: '0.125em',
  color: '#898988',
  paddingBottom: '0.125em',
  textDecoration: 'none'
};

const mobileActiveStyle = {
  color: `var(--custom-yellow)`
};

const TabletAndDesktopHeader = styled.header`
  display: none;
  ${mq.landscapeMobile`
    display: block;
    background-color: #000;
    padding: 0.85em 0 1em;
    position: relative;
  `}
`;

const MobileHeader = styled.div`
  ${flexStyle('column')};
  background-color: rgba(0, 0, 0, 0.85);
  border-top: 5px solid var(--custom-yellow);
  border-bottom: 0.1vw solid #fff;
  height: 60.375px;
  position: fixed;
  width: 100%;
  z-index: 1;

  ${mq.landscapeMobile`
    display: none;
  `}
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 71.5%;
  padding-left: 3%;

  ${mq.smallTablet`
    margin: 0 auto;
    padding-left: initial;
    width: 55%;
  `}
`;

const MenuLink = styled(Link)`
  color: #fff;
  font-weight: 600;
  font-size: 1.375em;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 1em;

  ${mq.landscapeMobile`
    font-size: 1.5em;
    margin-top: 0.3em;
  `}

`;
