import React from 'react';
import { Map } from 'components';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { mq, flexStyle } from 'sharedStyles';

const socialLinks = [
  'https://twitter.com/sportireland',
  'https://www.facebook.com/sportireland',
  'https://www.instagram.com/sportireland/'
];

export default function() {
  return (
    <Footer>
      <MapWrapper>
        <Map />
      </MapWrapper>
      <SmallPrint>
        <Address>
          <span>Address:</span>&nbsp;Sport Ireland, The Courtyard, Sport Ireland National Sports
          Campus, Snugborough Road, Blanchardstown, Dublin 15, D15 PN0N.
        </Address>
        <EmailAndTelephone>
          <span>Tel:</span>&nbsp;
          <a href="tel: +35318608800" target="__blank">
            +353 1 8608800
          </a>
          <span>&#65372;</span>
          <span>Email:</span>&nbsp;
          <a href="mailto: contactus@sportireland.ie">contactus@sportireland.ie</a>
        </EmailAndTelephone>
        <SocialImgWrapper>
          <StaticQuery
            query={SOCIAL_IMG_QUERY}
            render={({ socialImgs }) =>
              socialImgs.edges.map(({ node }, i) => (
                <a href={socialLinks[i]} target="__blank" key={node.childImageSharp.fluid.src}>
                  <SocialImg
                    fluid={node.childImageSharp.fluid}
                  />
                </a>
              ))
            }
          />
        </SocialImgWrapper>
        <ul>
          <li>© {new Date().getFullYear()}, Sport Ireland.</li>
          <li>&#65372;</li>
          <li><a href="https://www.sportireland.ie/" target="__blank">www.sportireland.ie</a></li>
        </ul>
        <Credit>
          Developed by{' '}
          <a href="https://www.agencyx.ie">
            Agency X
          </a>
        </Credit>
      </SmallPrint>
    </Footer>
  );
}

const SOCIAL_IMG_QUERY = graphql`
  query {
    socialImgs: allFile(
      filter: { relativeDirectory: { eq: "shared/social_images" } }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 45, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

/* prettier-ignore */
const Footer = styled.footer`
  border-top: 1px solid #000;
  font-family: proxima-nova, sans-serif;
  ${flexStyle('column')};

  @media screen and (min-width: 700px) {
    ${flexStyle('row-reverse')};
  }
`;

const ContactDetails = styled.p`
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.45em;

  span {
    font-weight: 600;
  }
`;

const Address = styled(ContactDetails)`
  margin-bottom: 0.25em;
`;

const EmailAndTelephone = styled(ContactDetails)`
  span:nth-of-type(2) {
    color: var(--custom-yellow);
  }
`;

const SmallPrint = styled.section`
  background: var(--custom-grey);
  padding: 2% 2%;
  width: 100%;
  text-align: center;

  ul {
    display: flex;
    font-size: 0.825em;
    font-weight: 400;
    justify-content: center;
    line-height: 1em;
    list-style: none;

    ${mq.largeTabletAndDesktop`
      line-height: 1.1em;
    `}
  }

  li:nth-child(odd) {
    text-align: center;
  }

  li:nth-child(even) {
    color: var(--custom-yellow);
    padding: 0 0.2em;
  }

  a {
    color: #000;
  }

  @media screen and (min-width: 700px) {
    width: 68%;
    text-align: left;

    ul {
      justify-content: flex-start;
    }
  }
`;

const Credit = styled.p`
  font-size: 0.825em;
  font-weight: 400;
  line-height: 1em;
  margin-top: 0.5em;

  ${mq.largeTabletAndDesktop`
    line-height: 1.1em;
  `}
`;

const SocialImgWrapper = styled.div`
  ${flexStyle('row', 'space-between', 'center')};
  padding: 0.75em 0 1.05em;
  margin: 0 auto;
  max-width: 185px;

  a {
    max-width: 45px;
    width: 100%;
  }

  @media screen and (min-width: 700px) {
    margin-left: 0;
  }
`;

const SocialImg = styled(Img)`
  border-radius: 50%;
  border: 0.5px solid #000;
  cursor: pointer;
  max-width: 45px;
  transition: filter 0.2s;

  ${mq.landscapeMobile`
    :hover {
      filter: none;
    }

    :not(:hover) {
      filter: grayscale(100%);
    }
  `}
`;

const MapWrapper = styled.section`
  ${flexStyle('row', 'center', 'center')};
  background-color: #fff;
  padding: 1.25%;
  width: 100%;
  height: 225px;

  @media screen and (min-width: 700px) {
    width: 38%;
    height: initial;
  }
`;
