import React, { Component } from 'react';
import markerIcon from 'images/shared/marker_icon.png';
import styled from 'styled-components';

export default class Map extends Component {
  componentDidMount() {
    const key = process.env.GATSBY_GOOGLE_MAPS_API_KEY;
    const googleMapsAPIModule = require('google-maps-api')(key, ['places']);
    googleMapsAPIModule()
      .then((mapObject) => {
        return new Promise((resolve) => {
          this.map = new mapObject.Map(this.mapRef, {
            zoom: 16,
            center: {
              lat: 53.39325,
              lng: -6.3573
            },
            mapTypeControl: false,
            scrollwheel: false,
            title: 'Google Map'
          });
          resolve(mapObject);
        });
      })
      .then((mapObject) => {
        new mapObject.Marker({
          position: {
            lat: 53.393019,
            lng: -6.3571304
          },
          title: 'Sport Ireland',
          map: this.map,
          icon: {
            url: markerIcon
          }
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Wrapper
        ref={(component) => {
          this.mapRef = component;
        }}
      />
    );
  }
}

const Wrapper = styled.div`
  border: 1px solid #000;
  width: 100%;
  height: 100%;
`;
