import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import defaultSiteThumbnail from 'static/thumbnail.jpg';

export default function SEO({
  keywords,
  lang,
  meta,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificTitle,
  thumbnailImageAlt
}) {
  return (
    <StaticQuery
      query={SEO_QUERY}
      render={(data) => {
        const {
          defaultSiteDescription,
          promozooTwitterPage,
          siteTitle,
          siteUrl
        } = data.site.siteMetadata;
        const description = pageSpecificDescription || defaultSiteDescription;
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={pageSpecificTitle}
            titleTemplate={`%s | ${siteTitle}`}
            meta={[
              {
                name: `description`,
                content: description
              },
              {
                property: `og:url`,
                content: siteUrl
              },
              {
                property: `og:title`,
                content: pageSpecificTitle
              },
              {
                property: `og:description`,
                content: description
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:image`,
                content: `${siteUrl}${pageSpecificThumbnail || defaultSiteThumbnail}`
              },
              {
                property: `og:image:url`,
                content: `${siteUrl}${pageSpecificThumbnail || defaultSiteThumbnail}`
              },
              {
                property: `og:image:secure_url`,
                content: `${siteUrl}${pageSpecificThumbnail || defaultSiteThumbnail}`
              },
              {
                property: `og:image:alt`,
                content: `${thumbnailImageAlt && thumbnailImageAlt}`
              },
              {
                property: 'og:image:width',
                content: '1200'
              },

              {
                property: 'og:image:height',
                content: '630'
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:creator`,
                content: promozooTwitterPage
              },
              {
                name: `twitter:title`,
                content: siteTitle
              },
              {
                name: `twitter:description`,
                content: description
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  pageSpecificDescription: PropTypes.string,
  pageSpecificTitle: PropTypes.string.isRequired
};

const SEO_QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        siteTitle
        defaultSiteDescription
        promozooTwitterPage
      }
    }
  }
`;
