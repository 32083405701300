import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import RightArrow from 'images/carousel/RightArrow';
import { useWindowSize } from 'hooks';
import PlayIcon from 'images/carousel/PlayIcon';

const carouselItemData = [
  {
    name: 'Welcome Video',
    vimeoCode: 445285442,
  },
  {
    name: 'Annalise Murphy',
    vimeoCode: 445288175,
  },
  {
    name: 'Fiona Coghlan',
    vimeoCode: 445286395,
  },
  {
    name: 'Kellie Harrington',
    vimeoCode: 445282929,
  },
  {
    name: 'Rosin Upton',
    vimeoCode: 445267774,
  },
  {
    name: 'Eve McCrystal',
    vimeoCode: 445276719,
  },
  {
    name: 'Natalya Coyle',
    vimeoCode: 445267773,
  },
  {
    name: 'Emma Johnstone',
    vimeoCode: 445276720,
  },
];

export default ({ handleVideoSelection }) => {
  const { windowWidth } = useWindowSize();
  return (
    <StaticQuery
      query={graphql`
        {
          carouselImgs: allFile(
            filter: { relativeDirectory: { eq: "carousel" }, ext: { eq: ".jpg" } }
            sort: { fields: [name], order: [ASC] }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fixed(width: 240, height: 135) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={({ carouselImgs }) => (
        <Wrapper>
          <h4>Click On Video To Play It</h4>
          <CarouselProvider
            naturalSlideWidth={240}
            naturalSlideHeight={135}
            visibleSlides={windowWidth >= 850 ? 3 : windowWidth >= 590 ? 2 : 1}
            totalSlides={8}>
            <SliderAndButtonsWrapper>
              <ButtonBack>
                <StyledLeftArrow />
              </ButtonBack>
              <Slider>
                {carouselImgs.edges.map((carouselImg, i) => (
                  <Slide
                    index={i}
                    key={carouselImg.node.name}
                    onClick={() => handleVideoSelection(carouselItemData[i].vimeoCode)}>
                    <CarouselItem>
                      <BackgroundImg fixed={carouselImg.node.childImageSharp.fixed} />
                      <Overlay>
                        <p>
                          PLAY <StyledPlayIcon alt="play icon" />
                          <br />
                          <span>{`- ${carouselItemData[i].name}`}</span>
                        </p>
                      </Overlay>
                    </CarouselItem>
                  </Slide>
                ))}
              </Slider>
              <ButtonNext>
                <StyledRightArrow />
              </ButtonNext>
            </SliderAndButtonsWrapper>
          </CarouselProvider>
        </Wrapper>
      )}
    />
  );
};

const SliderAndButtonsWrapper = styled.div`
  margin: 0 auto;
  max-width: 240px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 590px) {
    max-width: 500px;
  }

  @media screen and (min-width: 850px) {
    max-width: 750px;
  }

  > div {
    position: relative;
    left: 2px;
  }

  > button {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: calc(50% - 13px);

    &:first-of-type {
      left: -35px;
    }

    &:last-of-type {
      right: -35px;
    }

    &:disabled {
      cursor: default;

      svg g path {
        stroke: #999999;
        transition: stroke 150ms ease-in-out;
      }
    }
  }
`;

const StyledPlayIcon = styled(PlayIcon)`
  width: 1.35rem;
  position: relative;
  top: 3px;
  left: 1px;
`;

const StyledRightArrow = styled(RightArrow)`
  width: 0.85rem;
`;

const StyledLeftArrow = styled(StyledRightArrow)`
  transform: scaleX(-1);
`;

const Wrapper = styled.section`
  background-color: var(--custom-grey);
  padding: 1em 0 2em;
  width: 100%;
  height: 229px;

  h4 {
    color: #fff;
    font-family: proxima-nova, sans-serif;
    font-size: 1.125em;
    font-weight: 600;
    margin-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.875);
  position: absolute;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  width: 240px;
  height: 135px;
  display: flex;
  align-items: flex-end;

  p {
    color: var(--custom-yellow);
    font-family: proxima-nova, sans-serif;
    font-size: 1.325rem;
    font-weight: 500;
    line-height: 1em;
    margin: 0 0 0.4em 0.625em;

    span {
      font-size: 0.75em;
      letter-spacing: 0.01em;
    }
  }
`;

const CarouselItem = styled.div`
  width: 240px;
  height: 135px;

  &:hover {
    cursor: pointer;

    ${Overlay} {
      opacity: 1;
    }
  }
`;

const BackgroundImg = styled(Img)`
  height: 135px;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 240px;
`;
