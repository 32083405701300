import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { flexStyle } from '../sharedStyles';

export default function() {
  return (
    <StaticQuery
      query={graphql`
        query {
          largeImage: file(relativePath: { eq: "policy_page/large_image/01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 475, maxHeight: 620, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          smallImage1: file(relativePath: { eq: "policy_page/small_images/01.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 475, maxHeight: 345, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          smallImage2: file(relativePath: { eq: "policy_page/small_images/02.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 475, maxHeight: 265, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ largeImage, smallImage1, smallImage2 }) => (
        <ThreeImages>
          <LargeImgWrapper>
            <Img fluid={largeImage.childImageSharp.fluid} />
          </LargeImgWrapper>
          <SmallImagesWrapper>
            <Img
              fluid={smallImage1.childImageSharp.fluid}
              style={{
                height: '55.645161290323%'
              }}
            />
            <Img
              fluid={smallImage2.childImageSharp.fluid}
              style={{
                height: '42.741935483871%'
              }}
            />
          </SmallImagesWrapper>
        </ThreeImages>
      )}
    />
  );
}

/* prettier-ignore */
const ThreeImages = styled.div`
  ${flexStyle('row', 'space-between')};
  max-height: 620px;
`;

const LargeImgWrapper = styled.div`
  width: 49.5%;
`;

const SmallImagesWrapper = styled.div`
  ${flexStyle('column', 'space-between')};
  width: 49.5%;
`;
