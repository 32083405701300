import React from 'react';
import styled, { css } from 'styled-components';
import { flexStyle } from 'sharedStyles';

export default function({ children, includesWomenPolicyTag }) {
  return <Separator includesWomenPolicyTag={includesWomenPolicyTag}>{children}</Separator>;
}

/* prettier-ignore */
const Separator = styled.section`
  border-left: none;
  border-right: none;
  background-color: #fff;
  color: #000;
  ${flexStyle('row', 'center', 'center')};
  max-height: 63px;
  position: relative;
  text-transform: uppercase;
  ${({ includesWomenPolicyTag }) => includesWomenPolicyTag && css`padding: 5px 0`};

  h1 {
    font-family: 'VTKS', sans-serif;
    font-weight: 400;
    margin-top: 0.275em;
  }

  h3 {
    cursor: pointer;
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    margin: 0.9em 0;
  }

  h4 {
    font-family: proxima-nova, sans-serif;
    font-weight: 600;
    margin: 0.9em auto;
  }

  @media screen and (max-width: 575px) {
    ${({ includesWomenPolicyTag }) => includesWomenPolicyTag && flexStyle('row', 'space-between', 'center')};

    h4 {
      ${({ includesWomenPolicyTag }) => includesWomenPolicyTag && css`margin: 0.9em 0 0.9em 0.45em`};

    }
  }

  @media screen and (max-width: 427px) {
    h4 {
      ${({ includesWomenPolicyTag }) => includesWomenPolicyTag && css`width: 35%; text-align: center;`};
    }
  }
`;
