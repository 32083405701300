import React, { useState } from 'react';
import { GlobalStyle } from 'sharedStyles';
import { Header, Hero, Footer, RenderWhenReady } from 'components';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';

export default function Layout({ children }) {
  return (
    <Wrapper>
      <GlobalStyle />
      <Content>
        <Header />
        <Hero />
        <main>
          {children}
        </main>
        <Footer />
      </Content>
    </Wrapper>
  );
}

/* prettier-ignore */
const Wrapper = styled.div`
  background-color: #000;
`;

const Content = styled.div`
  background: #fff;
  margin: 0 auto;
  max-width: 960px;
  border-top: none;
  border-bottom: none;
`;
