import React from 'react';
import styled from 'styled-components';
import VimeoPlayer from 'react-player/vimeo';

function ResponsiveVideo({ isMobile, playingVimeoVideoCode, playing }) {
  return (
    <div>
      <FixedAspectRatioWrapper>
        <VimeoPlayer
          url={`https://player.vimeo.com/video/${playingVimeoVideoCode}`}
          playing={playing ? true : (playing === undefined && !isMobile)}
          loop
          controls
          volume={1}
          width="100%"
          height="100%"
          style={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
        />
      </FixedAspectRatioWrapper>
    </div>
  );
}

const FixedAspectRatioWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default ResponsiveVideo;
