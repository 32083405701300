import { createGlobalStyle, css } from 'styled-components';
import VTKSDistressTTF from 'fonts/vtks_distress.ttf';

export const flexStyle = (
  direction = 'row',
  justifyContent = 'flex-start',
  alignItems = 'stretch'
) => {
  return `
    align-items: ${alignItems};
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justifyContent};
  `;
};

const sizes = {
  largeTabletAndDesktop: 960,
  smallTablet: 768,
  landscapeMobile: 576
};

// Iterate through the sizes and create a media template
export const mq = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const GlobalStyle = createGlobalStyle`
  :root {
    --custom-grey: #b2b2b2;
    --custom-yellow: #F3e500;
  }

  @font-face {
    font-family: 'VTKS';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    src: url(${VTKSDistressTTF}) format('truetype');
  }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  /* All the below typography rules are taken from
  http://typecast.com/blog/a-more-modern-scale-for-web-typography */

  body, caption, th, td, input, textarea, select, option, legend, fieldset, h1, h2, h3, h4, h5, h6 {
    font-size-adjust: 0.5;
  }

  body {
    font-family: proxima-nova, sans-serif;
    font-size: 1em;
    line-height: 1.25em;

    ${mq.smallTablet`
      line-height: 1.375em;
    `}
  }

  h1 {
    font-size: 2em;
    line-height: 1.25em;

    ${mq.smallTablet`
      font-size: 2.5em;
      line-height: 1.125em;
    `}

    ${mq.largeTabletAndDesktop`
      font-size: 3em;
      line-height: 1.05em;
    `}
  }

  h2 {
    font-size: 1.625em;
    line-height: 1.15384615em;

    ${mq.smallTablet`
      font-size: 2em;
      line-height: 1.25em;
    `}

    ${mq.largeTabletAndDesktop`
      font-size: 2.25em;
    `}
  }

  h3 {
    font-size: 1.375em;
    line-height: 1.13636364em;

    ${mq.smallTablet`
      font-size: 1.5em;
      line-height: 1.25em;
    `}

    ${mq.largeTabletAndDesktop`
      font-size: 1.75em;
    `}
  }

  h4 {
    font-size: 1.125em;
    line-height: 1.11111111em;

    ${mq.smallTablet`
      line-height: 1.22222222em;
    `}
  }
`;
