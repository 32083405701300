import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Img from 'gatsby-image';
import { mq } from 'sharedStyles';
import { StaticQuery, graphql } from 'gatsby';
import { BackgroundOverlay, ResponsiveVideo } from 'components';
import Tile from 'images/shared/tile.png';

export default class HomePageVideo extends Component {
  state = {
    playingVimeoVideoCode: null
  };

  static getDerivedStateFromProps(props, state) {
    if (props.playingVimeoVideoCode !== state.playingVimeoVideoCode) {
      return {
        playingVimeoVideoCode: props.playingVimeoVideoCode
      };
    }
    return null;
  }

  render() {
    const { handleVideoSelection } = this.props;
    const { playingVimeoVideoCode } = this.state;
    return (
      <StaticQuery
        query={HOMEPAGE_VIDEO_QUERY}
        render={({ allAthletesAndKids, clickToWatch }) => {
          return (
            <Wrapper>
              <BackgroundOverlay />
              {isMobile || playingVimeoVideoCode ? (
                <ResponsiveVideo
                  isMobile={isMobile}
                  playingVimeoVideoCode={playingVimeoVideoCode}
                />
              ) : (
                <>
                  <AllAthletesAndKidsImg fluid={allAthletesAndKids.childImageSharp.fluid} />
                  <ForegroundOverlay onClick={() => handleVideoSelection(445285442)} />
                  <WelcomeMessageWrapper>
                    <WelcomeMessage>The Heroes Of Today</WelcomeMessage>
                  </WelcomeMessageWrapper>
                  <div role="button" tabIndex={0} onClick={() => handleVideoSelection(445285442)}>
                    <ClickToWatchImg
                      style={{
                        position: 'absolute'
                      }}
                      fluid={clickToWatch.childImageSharp.fluid}
                    />
                  </div>
                </>
              )}
            </Wrapper>
          );
        }}
      />
    );
  }
}

const HOMEPAGE_VIDEO_QUERY = graphql`
  query {
    allAthletesAndKids: file(relativePath: { eq: "home_page/all_athletes_and_kids.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    clickToWatch: file(relativePath: { eq: "home_page/click_to_watch_yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 159, maxHeight: 159, quality: 25) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

/* prettier-ignore */
const Wrapper = styled.section`
  background: #000 url(${Tile});
  position: relative;
  width: 100%;
`;

const AllAthletesAndKidsImg = styled(Img)`
  width: 100%;
  position: relative;
  bottom: 1px;
`;

const ForegroundOverlay = styled.div`
  background: linear-gradient(
    0deg,
    rgba(000, 000, 000, 1) 0%,
    rgba(000, 000, 000, 0.8) 1%,
    transparent 20%
  );
  bottom: 0;
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const WelcomeMessageWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  margin: 2.75% auto 0;
`;

const WelcomeMessage = styled.h1`
  font-family: 'VTKS', sans-serif;
  font-weight: 400;
  color: #fff;
  text-align: center;
  font-size: 2em;
  line-height: 1.25em;

  @media screen and (min-width: 395px) {
    font-size: 2.2em;
  }

  @media screen and (min-width: 395px) {
    font-size: 2.5em;
  }

  ${mq.landscapeMobile`
    font-size: 3em;
    line-height: 1.125em;
  `}

  ${mq.smallTablet`
    font-size: 3.5em;
    line-height: 1.05em;
  `}
`;

const ClickToWatchImg = styled(Img)`
  cursor: pointer;
  max-width: 95px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -32%);
  transition: filter 0.2s;

  :hover {
    filter: none;
  }

  :not(:hover) {
    filter: grayscale(100%);
  }

  @media screen and (min-width: 395px) {
    max-width: 120px;
  }

  ${mq.landscapeMobile`
    max-width: 159px;
  `}
`;
