import React, { Component } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import 'react-image-lightbox/style.css';

export default class ImageGallery extends Component {
  state = {
    imageIndex: 0,
    isOpen: false
  };

  openLightBox = (imageIndex) => {
    this.setState({ imageIndex, isOpen: true });
  };

  render() {
    const { imageIndex, isOpen } = this.state;
    return (
      <Wrapper>
        <StaticQuery
          query={IMAGES_QUERY}
          render={({ images }) => {
            const totalImages = images.edges.length;
            return (
              <>
                {images.edges.map(({ node }, i) => {
                  const { smallImage } = node.childImageSharp;
                  return (
                    <ImgWrapper
                      role="presentation"
                      onClick={() => this.openLightBox(i)}
                      key={smallImage.src}>
                      <Img fluid={smallImage} />
                    </ImgWrapper>
                  );
                })}
                {isOpen && (
                  <Lightbox
                    mainSrc={images.edges[imageIndex].node.childImageSharp.largeImage.src}
                    nextSrc={
                      images.edges[(imageIndex + 1) % totalImages].node.childImageSharp.largeImage
                        .src
                    }
                    prevSrc={
                      images.edges[(imageIndex + totalImages - 1) % totalImages].node
                        .childImageSharp.largeImage.src
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        imageIndex: (imageIndex + totalImages - 1) % totalImages
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        imageIndex: (imageIndex + 1) % totalImages
                      })
                    }
                  />
                )}
              </>
            );
          }}
        />
      </Wrapper>
    );
  }
}

const IMAGES_QUERY = graphql`
  query {
    images: allFile(
      filter: {
        extension: { regex: "/(png)/" }
        relativeDirectory: { regex: "/home_page/grid_images/" }
      }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            smallImage: fluid(maxWidth: 288, maxHeight: 288, quality: 40) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG

            }
            largeImage: fluid(maxWidth: 750, maxHeight: 750, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;

/* prettier-ignore */
const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

const ImgWrapper = styled.div`
  cursor: pointer;
  width: 50%;

  @media screen and (min-width: 384px) {
    flex-grow: 1;
    flex-basis: 191px;
    height: 100%;
    width: 100%;
  }
`;
