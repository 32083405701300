import * as React from 'react';

function SvgRightArrow(props) {
  return (
    <svg viewBox="0 0 16.24 26.83" {...props}>
      <g data-name="Layer 2">
        <path
          fill="none"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={4}
          d="M1.41 1.41l12 12-12 12"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgRightArrow;
