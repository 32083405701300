import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link, StaticQuery, graphql } from 'gatsby';

export default function() {
  return (
    <StaticQuery
      query={graphql`
        query {
          womenPolicy: file(relativePath: { eq: "shared/women_policy.png" }) {
            childImageSharp {
              fluid(maxWidth: 182, maxHeight: 56, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={({ womenPolicy }) => (
        <LinkToPolicyPage to="/policy">
          <Img fluid={womenPolicy.childImageSharp.fluid} />
          <TextWrapper>
            <p>Women In Sport Policy</p>
            <p>(click for more)</p>
          </TextWrapper>
        </LinkToPolicyPage>
      )}
    />
  );
}

/* prettier-ignore */
const LinkToPolicyPage = styled(Link)`
  line-height: 0.95em;
  max-width: 182px;
  position: absolute;
  right: 0;
  width: 100%;

  p {
    color: #000;
    font-size: 0.85em;
  }

  & p:first-of-type {
    font-weight: 600;
  }

  & p:nth-of-type(2) {
    text-transform: lowercase;
  }
`;

const TextWrapper = styled.div`
  margin-top: 0.4em;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 73%;
`;
